
module.exports = (function(){
  var d = _eai_d;
  var r = _eai_r;
  window.emberAutoImportDynamic = function(specifier) {
    if (arguments.length === 1) {
      return r('_eai_dyn_' + specifier);
    } else {
      return r('_eai_dynt_' + specifier)(Array.prototype.slice.call(arguments, 1))
    }
  };
  window.emberAutoImportSync = function(specifier) {
    return r('_eai_sync_' + specifier)(Array.prototype.slice.call(arguments, 1))
  };
  function esc(m) {
    return m && m.__esModule ? m : Object.assign({ default: m }, m);
  }
    d('@aws-amplify/auth', EAI_DISCOVERED_EXTERNALS('@aws-amplify/auth'), function() { return esc(require('@aws-amplify/auth')); });
    d('@js-joda/core', EAI_DISCOVERED_EXTERNALS('@js-joda/core'), function() { return esc(require('@js-joda/core')); });
    d('@js-joda/timezone', EAI_DISCOVERED_EXTERNALS('@js-joda/timezone'), function() { return esc(require('@js-joda/timezone')); });
    d('@sentry/browser', EAI_DISCOVERED_EXTERNALS('@sentry/browser'), function() { return esc(require('@sentry/browser')); });
    d('@sentry/core', EAI_DISCOVERED_EXTERNALS('@sentry/core'), function() { return esc(require('@sentry/core')); });
    d('@sentry/utils', EAI_DISCOVERED_EXTERNALS('@sentry/utils'), function() { return esc(require('@sentry/utils')); });
    d('chart.js/auto', EAI_DISCOVERED_EXTERNALS('chart.js/auto'), function() { return esc(require('chart.js/auto')); });
    d('chartjs-adapter-luxon', EAI_DISCOVERED_EXTERNALS('chartjs-adapter-luxon'), function() { return esc(require('chartjs-adapter-luxon')); });
    d('ember-element-helper/helpers/element', EAI_DISCOVERED_EXTERNALS('ember-element-helper/helpers/element'), function() { return esc(require('ember-element-helper/helpers/element')); });
    d('ember-modifier', EAI_DISCOVERED_EXTERNALS('ember-modifier'), function() { return esc(require('ember-modifier')); });
    d('ember-moment/helpers/-base.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/-base.js'), function() { return esc(require('ember-moment/helpers/-base.js')); });
    d('ember-moment/helpers/is-after.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/is-after.js'), function() { return esc(require('ember-moment/helpers/is-after.js')); });
    d('ember-moment/helpers/is-before.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/is-before.js'), function() { return esc(require('ember-moment/helpers/is-before.js')); });
    d('ember-moment/helpers/is-between.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/is-between.js'), function() { return esc(require('ember-moment/helpers/is-between.js')); });
    d('ember-moment/helpers/is-same-or-after.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/is-same-or-after.js'), function() { return esc(require('ember-moment/helpers/is-same-or-after.js')); });
    d('ember-moment/helpers/is-same-or-before.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/is-same-or-before.js'), function() { return esc(require('ember-moment/helpers/is-same-or-before.js')); });
    d('ember-moment/helpers/is-same.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/is-same.js'), function() { return esc(require('ember-moment/helpers/is-same.js')); });
    d('ember-moment/helpers/moment-add.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-add.js'), function() { return esc(require('ember-moment/helpers/moment-add.js')); });
    d('ember-moment/helpers/moment-calendar.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-calendar.js'), function() { return esc(require('ember-moment/helpers/moment-calendar.js')); });
    d('ember-moment/helpers/moment-diff.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-diff.js'), function() { return esc(require('ember-moment/helpers/moment-diff.js')); });
    d('ember-moment/helpers/moment-duration.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-duration.js'), function() { return esc(require('ember-moment/helpers/moment-duration.js')); });
    d('ember-moment/helpers/moment-format.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-format.js'), function() { return esc(require('ember-moment/helpers/moment-format.js')); });
    d('ember-moment/helpers/moment-from-now.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-from-now.js'), function() { return esc(require('ember-moment/helpers/moment-from-now.js')); });
    d('ember-moment/helpers/moment-from.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-from.js'), function() { return esc(require('ember-moment/helpers/moment-from.js')); });
    d('ember-moment/helpers/moment-subtract.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-subtract.js'), function() { return esc(require('ember-moment/helpers/moment-subtract.js')); });
    d('ember-moment/helpers/moment-to-date.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-to-date.js'), function() { return esc(require('ember-moment/helpers/moment-to-date.js')); });
    d('ember-moment/helpers/moment-to-now.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-to-now.js'), function() { return esc(require('ember-moment/helpers/moment-to-now.js')); });
    d('ember-moment/helpers/moment-to.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment-to.js'), function() { return esc(require('ember-moment/helpers/moment-to.js')); });
    d('ember-moment/helpers/moment.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/moment.js'), function() { return esc(require('ember-moment/helpers/moment.js')); });
    d('ember-moment/helpers/now.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/now.js'), function() { return esc(require('ember-moment/helpers/now.js')); });
    d('ember-moment/helpers/unix.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/unix.js'), function() { return esc(require('ember-moment/helpers/unix.js')); });
    d('ember-moment/helpers/utc.js', EAI_DISCOVERED_EXTERNALS('ember-moment/helpers/utc.js'), function() { return esc(require('ember-moment/helpers/utc.js')); });
    d('ember-moment/services/moment.js', EAI_DISCOVERED_EXTERNALS('ember-moment/services/moment.js'), function() { return esc(require('ember-moment/services/moment.js')); });
    d('events', EAI_DISCOVERED_EXTERNALS('events'), function() { return esc(require('events')); });
    d('fast-memoize', EAI_DISCOVERED_EXTERNALS('fast-memoize'), function() { return esc(require('fast-memoize')); });
    d('intl-messageformat', EAI_DISCOVERED_EXTERNALS('intl-messageformat'), function() { return esc(require('intl-messageformat')); });
    d('intl-messageformat-parser', EAI_DISCOVERED_EXTERNALS('intl-messageformat-parser'), function() { return esc(require('intl-messageformat-parser')); });
    d('js-quantities', EAI_DISCOVERED_EXTERNALS('js-quantities'), function() { return esc(require('js-quantities')); });
    d('lodash/range', EAI_DISCOVERED_EXTERNALS('lodash/range'), function() { return esc(require('lodash/range')); });
    d('luxon', EAI_DISCOVERED_EXTERNALS('luxon'), function() { return esc(require('luxon')); });
    d('mapbox-gl', EAI_DISCOVERED_EXTERNALS('mapbox-gl'), function() { return esc(require('mapbox-gl')); });
    d('moment', EAI_DISCOVERED_EXTERNALS('moment'), function() { return esc(require('moment')); });
    d('uuid', EAI_DISCOVERED_EXTERNALS('uuid'), function() { return esc(require('uuid')); });
    d('validated-changeset', EAI_DISCOVERED_EXTERNALS('validated-changeset'), function() { return esc(require('validated-changeset')); });
    d('_eai_dyn_@sentry/browser', [], function() { return import('@sentry/browser'); });
    d('_eai_dyn_mapbox-gl', [], function() { return import('mapbox-gl'); });
})();
